const defaultTheme = {
  // bg: "#85DCB0",
  bg: "white",
  primary: "white",
  // primary: "#41B3A3",
  light: "#FFFFFF",
  accent: "#E27D60",
  text: {
    color: "black",
    muted: "#BDBEBE",
    light: "#FFFFFF",
    code: "#000000",
    codeBg: "#E7E7E7",
  },
  code: {
    color: "#DCDCDC",
    bg: "#15191E",
  },
}


export default defaultTheme
